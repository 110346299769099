<template>
  <div class="columns flex flex-col-reverse md:flex-row">
    <div class="column is-8" v-if="getGarantias">
      <div
        class="card has-border-radius shadow-none px-4 h-full"
        :class="{ 'flex justify-center': getGarantias.length == 0 }"
      >
        <div v-if="getGarantias.length == 0">
            <empty-state
              type="svg"
              imagen="/images/movimientos.svg"
              titulo="No tienes garantias"
              subtitulo="Ligadas a esta línea de crédito"
            />
          </div>
        <div v-else class="columns is-multiline ">
          <div
            class="column is-12"
            v-for="garantia in getGarantias"
            :key="garantia.garantia.id"
          >
            <garantia-card :garantia="garantia.garantia" @reclamar="reclamar" />
          </div>
        </div>
      </div>
    </div>
    <div class="column is-4">
      <sidebar
        tipo="linea"
        @toggle-cf="toggleCapvitalFiles"
        :acreditado="cliente.id"
        :archivos="getArchivosGarantia"
        :pasiva="linea.pasiva ? true : false"
        @asignar-garantia="asignarGarantia"
        @asignar-garantia-existente="asignarGarantiaExistente"
      />
    </div>
    <portal to="modalContainer">
      <b-modal
        v-if="is_modal_open"
        :active.sync="is_modal_open"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
      >
        <modal-reclamar-garantia
          :garantia="garantia_reclamar"
          @reclamada="reloadGarantias"
        />
      </b-modal>
    </portal>
  </div>
</template>

<script>
import GarantiasApi from "@/api/garantias.api";
import { mapGetters } from "vuex";
import EmptyState from "@/components/generals/EmptyState";
import Sidebar from "@/components/garantias/Sidebar";
import GarantiaCard from "@/components/cards/GarantiaCard";
import ModalReclamarGarantia from "@/components/modals/ModalReclamarGarantia";

export default {
  name: "LineaDetalleGarantia",
  components: {
    EmptyState,
    Sidebar,
    GarantiaCard,
    ModalReclamarGarantia
  },
  data() {
    return {
      garantia_reclamar: null,
      is_modal_open: false
    };
  },
  props: {
    linea: Object
  },
  methods: {
    reclamar(data) {
      this.is_modal_open = true;
      this.garantia_reclamar = data;
    },
    async reloadGarantias() {
      await this.$store.dispatch(
        "lineascredito/getGarantias",
        {
          linea_id: this.$route.params.idLinea
        }      
      );
    },
    toggleCapvitalFiles(status) {
      let data = {
        type: "garantias_linea",
        id: this.$route.params.idLinea,
        persona: this.getLinea.relaciones.find(x => x.rol.nombre == "Cliente")
          .relacionado,
        status: status
      };
      this.$store.dispatch("files/toggleCapvitalFiles", data);
    },
    asignarGarantia(form) {
      form.linea_id = this.$route.params.idLinea;
      this.$store.dispatch("lineascredito/asignarGarantia", form);
    },
    async asignarGarantiaExistente(garantia_id) {
      let prelacion = 0;
      const garantia = await GarantiasApi.getGarantiaById(garantia_id);
      if (garantia.data && garantia.data.atributos.length > 0) {
        const prelacionGarania = garantia.data.atributos.find(atributo => atributo.nombre_atributo == 'Prelación');
        prelacion = prelacionGarania ? prelacionGarania.valor : 0;
      }

      const linea_id = this.$route.params.idLinea;
      const lineas_credito = {};
      lineas_credito[linea_id] = { prelacion };
      
      this.$store
        .dispatch("lineascredito/asignarGarantiaExistente", {
          garantia_id: garantia_id,
          lineas_credito
        })
        .then(() => this.reloadGarantias());
    }
  },
  computed: {
    ...mapGetters("lineascredito", [
      "getGarantias",
      "getLinea",
      "getArchivosGarantia"
    ]),
    cliente() {
      let relacion;
      if (this.linea.pasiva == 1 && this.linea.relaciones) {
        relacion = this.linea.relaciones.filter(
          x => x.rol.nombre == "Fuente de fondeo"
        );
      } else {
        relacion = this.linea.relaciones.filter(x => x.rol.nombre == "Cliente");
      }
      if (relacion.length > 0) {
        return {
          nombre: relacion[0].relacionado.nombre_completo.split("|")[0],
          id: relacion[0].relacionado.id
        };
      }
      return null;
    },
  },
  async created() {
    await this.$store.dispatch(
      "lineascredito/getGarantias",
      {
        linea_id: this.$route.params.idLinea
      }
    );
  }
};
</script>
