<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-12">
        <actividad-reciente :id="linea.id" tipo="linea" />
      </div>
      <div class="column is-4">
        <monto-card
          tipo="linea"
          :capital="linea.capital_disponible"
          :moneda="linea.moneda.clave"
          :autorizado="linea.capital_autorizado"
          :pasiva="linea.pasiva ? true : false"
          :arrendamiento="esArrendamiento"
          :status="linea.status_linea_directo.nombre"
        />
      </div>
      <div class="column is-4">
        <status-card
          :status="linea.status_linea_directo"
          :fecha_entrega="linea.fecha_autorizacion"
          tipo="linea"
        />
      </div>
      <div class="column is-4">
        <checklist-card
          :total="linea.requisitos_count.total"
          :progreso="linea.requisitos_count.aceptados"
          type="is-success"
        />
      </div>
      <div v-if="esArrendamiento" class="column is-12">
        <div class="flex justify-between items-center">
          <p class="text-xl font-bold">Activos por arrendar</p>
          <b-dropdown
            aria-role="list"
          >
            <template #trigger>
              <b-button
                label="Agregar activo"
                type="is-arrendamiento"
                icon-left="plus"
                class="h-6 px-6 text-xs"
              />
            </template>

            <b-dropdown-item
              @click="() => isModalAgregarActivoOpen = true"
              aria-role="listitem"
              class="text-left"
            >
              Crear un Activo nuevo
            </b-dropdown-item>
            <b-dropdown-item
              @click="() => isModalActivoExistenteOpen = true"
              aria-role="listitem"
              class="text-left"
            >
              Activo existente
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div v-if="getGarantias" class="columns is-multiline mt-3">
          <div
            class="column is-12"
            v-for="(garantia, index) in getGarantias"
            :key="index"
          >
            <garantia-card style="max-height: 120px;" :garantia="garantia.garantia" :arrendamiento="esArrendamiento" @crearArrendamiento="crearArrendamiento" />
          </div>
          <div class="m-auto" v-if="getGarantias.length == 0">
            <empty-state
              type="svg"
              imagen="/images/movimientos.svg"
              titulo="No tienes activos"
              subtitulo="Ligados a esta línea de crédito"
            />
          </div>
        </div>
        <p class="text-xl font-bold text-left">Activos arrendados</p>
        <div v-if="getGarantiasArrendadas" class="columns is-multiline mt-3">
          <div
            class="column is-12"
            v-for="(garantia, index) in getGarantiasArrendadas"
            :key="index"
          >
            <garantia-card style="max-height: 120px;" :garantia="garantia.garantia" :arrendamiento="esArrendamiento" />
          </div>
          <div class="m-auto" v-if="getGarantiasArrendadas.length == 0">
            <empty-state
              type="svg"
              imagen="/images/movimientos.svg"
              titulo="No tienes activos"
              subtitulo="Aún no has creado arrendamientos"
            />
          </div>
        </div>
      </div>
      <div class="column is-12">
        <linea-info-card :linea="linea" />
      </div>
      <div class="column is-12 text-left overflow-x-hidden">
        <div class="columns">
          <div class="column">
            <p class="text-xl font-bold">Personas relacionadas de la línea</p>
          </div>
          <div class="column text-right">
            <b-button
              :type="linea.pasiva ? 'is-pasivo' : esArrendamiento ? 'is-arrendamiento' : 'is-primary'"
              icon-left="plus"
              class="font-medium"
              tag="router-link"
              :to="{ name: 'colaboradores_linea' }"
              >Nueva persona</b-button
            >
          </div>
        </div>
        <b-carousel-list
          v-model="activePersona"
          class="overflow-visible shadow-none"
          :data="linea.relaciones"
          :arrow="true"
          :arrow-hover="true"
          :items-to-list="1"
          :repeat="true"
          v-bind="configCarousel"
          :indicator="true"
          :indicator-inside="false"
          :has-drag="true"
          :has-opacity="true"
          @switch="val => (activePersona = val)"
        >
          <template slot="item" slot-scope="props">
            <div class="px-3">
              <persona-card :persona="props" />
            </div>
          </template>
        </b-carousel-list>
      </div>
    </div>
    <portal to="modalContainer">
      <b-modal
        v-if="isModalAgregarActivoOpen"
        :active.sync="isModalAgregarActivoOpen"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
        class="agregar-activo"
      >
        <modal-agregar-activo @toggle-cf="toggleCapvitalFiles" />
      </b-modal>
      <b-modal
        v-if="isModalActivoExistenteOpen"
        :active.sync="isModalActivoExistenteOpen"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
        class="agregar-activo"
      >
        <modal-activo-existente />
      </b-modal>
    </portal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MontoCard from "@/components/cards/MontoCard";
import ChecklistCard from "@/components/cards/ChecklistCard";
import StatusCard from "@/components/cards/StatusCard";
import ActividadReciente from "@/components/Lineas/detalle/ActividadReciente";
import LineaInfoCard from "@/components/Lineas/detalle/LineaInfoCard";
import PersonaCard from "@/components/cards/PersonaCard";
import ModalAgregarActivo from "@/components/modals/ModalAgregarActivo.vue";
import ModalActivoExistente from "@/components/modals/ModalActivoExistente.vue";
import GarantiaCard from "@/components/cards/GarantiaCard";
import EmptyState from "@/components/generals/EmptyState";

export default {
  name: "LineaDetalleInformacion",
  components: {
    MontoCard,
    StatusCard,
    ChecklistCard,
    ActividadReciente,
    LineaInfoCard,
    PersonaCard,
    ModalAgregarActivo,
    ModalActivoExistente,
    GarantiaCard,
    EmptyState
  },
  props: {
    linea: Object
  },
  data() {
    return {
      activePersona: null,
      isModalAgregarActivoOpen: false,
      isModalActivoExistenteOpen: false,
      configCarousel: {
        itemsToShow: 2,
        breakpoints: {
          768: {
            hasGrayscale: false,
            itemsToShow: 3
          },
          960: {
            hasGrayscale: true,
            itemsToShow: 4
          },
          1200: {
            hasGrayscale: true,
            itemsToShow: 5
          }
        }
      }
    };
  },
  computed: {
    ...mapGetters("lineascredito", ["getGarantias", "getGarantiasArrendadas"]),

    esArrendamiento() {
      return this.linea ? this.linea.producto_financiero.tipo_credito.tipo === "Arrendamiento Puro" : false;
    },
  },
  methods: {
    async crearArrendamiento(activo) {
      localStorage.removeItem("pasos");
      await this.$store.dispatch("garantias/setGarantiaParaArrendar", activo);
      this.$router.push({
        name: "solicitar_arrendamiento",
        params: { linea_credito_id: this.$route.params.idLinea }
      });
    },
    toggleCapvitalFiles(status) {
      let data = {
        type: "garantias_linea",
        id: this.linea.id,
        persona: this.linea.relaciones.find(x => x.rol.nombre == "Cliente")
          .relacionado,
        status: status
      };
      this.$store.dispatch("files/toggleCapvitalFiles", data);
    },
  },
  async created() {
    await this.$store.dispatch(
          "lineascredito/getGarantias",
          {
            linea_id: this.linea.id,
            arrendables: true
          }
    );

    await this.$store.dispatch("lineascredito/getGarantiasArrendadas", this.linea.id);
  }
};
</script>
