<template>
  <div class="has-background-white-ter has-border-radius p-4 text-left">
    <p class="text-xl font-bold">Información de la línea</p>
    <div class="columns mt-2 is-multiline">
      <div class="column is-4">
        <p class="has-text-grey-light font-light">Producto financiero</p>
        <p>
          {{
            linea.producto_financiero ? linea.producto_financiero.alias : "N/A"
          }}
        </p>
      </div>
      <div class="column is-4">
        <p class="has-text-grey-light font-light">Ejecutivo de cuenta</p>
        <p>
          <preview v-if="linea.empleado" tipo="persona" :id="linea.empleado.id">
            {{ linea.empleado.nombre_completo.split("|")[0] }}
          </preview>
          <span v-else>N/A</span>
        </p>
      </div>
      <div class="column is-4">
        <p class="has-text-grey-light font-light">
          {{ linea.pasiva ? "Fuente de fondeo" : "Acreditado" }}
        </p>
        <p>
          <preview tipo="persona" :id="cliente.id">
            {{ cliente.nombre }}
          </preview>
        </p>
      </div>
      <div class="column is-4">
        <p class="has-text-grey-light font-light">Sucursal</p>
        <p>{{ linea.sucursal.nombre }}</p>
      </div>
      <div class="column is-4">
        <p class="has-text-grey-light font-light">Garantía</p>
        <p>{{ linea.tipos_garantia ? linea.tipos_garantia.nombre : "N/A" }}</p>
      </div>
      <div class="column is-4">
        <p class="has-text-grey-light font-light">Segmento de negocio</p>
        <p>
          {{ linea.segmento_negocio ? linea.segmento_negocio.nombre : "N/A" }}
        </p>
      </div>
      <div class="column is-4">
        <p class="has-text-grey-light font-light">Revolvente</p>
        <p>
          {{ linea.revolvente ? "Sí" : "No" }}
        </p>
      </div>
    </div>
    <div class="rule-grey" />
    <div class="columns mt-2 is-multiline">
      <div class="column is-4">
        <p class="has-text-grey-light font-light">Fecha de autorización</p>
        <p>
          <span v-if="linea.fecha_autorizacion">
            {{ linea.fecha_autorizacion | moment("DD-MM-YYYY") }}
          </span>
          <span v-else>N/A</span>
        </p>
      </div>
      <div class="column is-4">
        <p class="has-text-grey-light font-light">Fecha de caducidad</p>
        <p>
          <span v-if="linea.fechaCaducidad">
            {{ linea.fechaCaducidad | moment("DD-MM-YYYY") }}
          </span>
          <span v-else>N/A</span>
        </p>
      </div>
      <div class="column is-4">
        <p class="has-text-grey-light font-light">
          Fecha máxima para cambiar fuente de fondeo
        </p>
        <p>
          <span v-if="linea.fechaMaximaCambioFuenteFondeo">
            {{ linea.fechaMaximaCambioFuenteFondeo | moment("DD-MM-YYYY") }}
          </span>
          <span v-else>N/A</span>
        </p>
      </div>
      <div class="column is-4">
        <p class="has-text-grey-light font-light">
          Fecha máxima de contratación
        </p>
        <p>
          <span v-if="linea.fechaMaximaContratacion">
            {{ linea.fechaMaximaContratacion | moment("DD-MM-YYYY") }}
          </span>
          <span v-else>N/A</span>
        </p>
      </div>

      <div class="column is-4">
        <p class="has-text-grey-light font-light">
          Fecha máxima de disposición
        </p>
        <p>
          <span v-if="linea.fechaMaximaDisposicion">
            {{ linea.fechaMaximaDisposicion | moment("DD-MM-YYYY") }}
          </span>
          <span v-else>N/A</span>
        </p>
      </div>
      <div class="column is-4">
        <p class="has-text-grey-light font-light">Fecha de recalificación</p>
        <p>
          <span v-if="linea.fecha_ultima_recalificacion">
            {{ linea.fecha_ultima_recalificacion | moment("DD-MM-YYYY") }}
          </span>
          <span v-else>N/A</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LineaInfoCard",
  props: {
    linea: Object
  },
  computed: {
    cliente() {
      let relacion;
      if (this.linea.pasiva == 1 && this.linea.relaciones) {
        relacion = this.linea.relaciones.filter(
          x => x.rol.nombre == "Fuente de fondeo"
        );
      } else {
        relacion = this.linea.relaciones.filter(x => x.rol.nombre == "Cliente");
      }
      if (relacion.length > 0) {
        return {
          nombre: relacion[0].relacionado.nombre_completo.split("|")[0],
          id: relacion[0].relacionado.id
        };
      }
      return "N/A";
    }
  }
};
</script>
