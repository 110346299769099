var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.getDisposiciones != null)?_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-9 text-left flex items-center"},[_c('p',{staticClass:"text-2xl font-bold"},[_vm._v(_vm._s(_vm.arrendamiento ? "Arrendamientos" : "Disposiciones"))])]),_c('div',{staticClass:"column is-3 text-right"},[(!_vm.esCreditoGrupal)?_c('b-button',{attrs:{"type":_vm.linea.pasiva 
          ? 'is-pasivo' 
          : _vm.arrendamiento ? 'is-arrendamiento' : 'is-primary',"icon-left":"plus","tag":"router-link","to":{
        name: _vm.arrendamiento ? 'solicitar_arrendamiento' : 'solicitar_disposicion',
        params: { linea_credito_id: this.$route.params.idLinea }
      }}},[_vm._v(" "+_vm._s(_vm.arrendamiento ? 'Nuevo arrendamiento' : 'Nueva disposición')+" ")]):_vm._e(),(_vm.esCreditoGrupal && _vm.getDisposiciones.disposiciones.length == 0)?_c('b-button',{attrs:{"type":"is-primary"},on:{"click":_vm.entregarDisposiciones}},[_vm._v("Entregar disposiciones")]):_vm._e()],1),(_vm.getDisposiciones.disposiciones.length > 0)?_c('div',{staticClass:"column is-12 pt-0 -mt-8"},[_c('filtros',{attrs:{"disposiciones":_vm.getDisposiciones.disposiciones,"tipo_disposicion":_vm.linea.pasiva ? 'pasivas' : 'activas',"pagina":_vm.currentPage,"linea-id":Number.parseInt(_vm.$route.params.idLinea)}})],1):_vm._e(),(_vm.getDisposiciones.disposiciones.length > 0)?_c('div',{staticClass:"column is-12 text-left"},_vm._l((_vm.getDisposicionesFiltradas
        ? _vm.getDisposicionesFiltradas.disposiciones
        : _vm.getDisposiciones.disposiciones),function(disposicion){return _c('disposicion-card',{key:disposicion.creditos_core_id,attrs:{"disposicion":disposicion,"arrendamiento":_vm.arrendamiento}})}),1):_c('div',{staticClass:"column is-12"},[_c('empty-state',{attrs:{"type":"svg","imagen":"/images/cuentas_cp.svg","titulo":("No Hay " + (_vm.arrendamiento ? 'arrendamientos' : 'disposiciones') + " en esta línea"),"subtitulo":("Crea " + (_vm.arrendamiento ? 'uno' : 'una') + " en el botón de arriba a la derecha o intenta con otro filtro.")}})],1),_c('b-pagination',{staticClass:"mb-8 pl-3",class:{'is-arrendamiento': _vm.arrendamiento},attrs:{"total":_vm.getDisposicionesFiltradas
        ? _vm.getDisposicionesFiltradas.total
        : _vm.getDisposiciones.total,"current":_vm.currentPage,"range-before":2,"range-after":2,"per-page":_vm.perPage,"icon-prev":"chevron-left","icon-next":"chevron-right","aria-next-label":"Página siguiente","aria-previous-label":"Página anterior","aria-page-label":"Página","aria-current-label":"Página actual"},on:{"update:current":function($event){_vm.currentPage=$event}}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }