<template>
  <div class="columns is-multiline">
    <div class="column is-12">
      <b-tag
        class="cursor-pointer tag-clickable mr-3 mb-2"
        @click.native="activeTag = tipo.id"
        :class="{ 
          'is-active': tipo.id == activeTag,
          'pasivo': getLinea ? getLinea.pasiva : false,
          'arrendamiento': arrendamiento 
        }"
        v-for="tipo in tiposBitacora"
        :key="tipo.id"
      >
        {{ tipo.nombre }}
        ({{ totalPorTipo(tipo.id) }})
      </b-tag>
    </div>
    <div class="column is-12">
      <div v-for="item in bitacoraActiva" :key="item.id">
        <div
          class="has-border-radius p-1 mb-8 columns"
          :class="{ 'has-background-primary-04': item.deleted_at == null }"
          v-if="activeTag == 'status_lineas'"
        >
          <div class="column is-12">
            <p>
              <span class="font-light has-text-grey-light text-md ml-2">
                {{ item.updated_at | moment("DD-MM-YY h:mm a") }}</span
              >
            </p>
            <p>
              <preview
                :id="item.author.id"
                tipo="persona"
                class="has-text-primary underline font-medium"
                >{{ item.author.nombre_completo.split("|")[0] }}</preview
              >
              Realizó un cambio de status a
              <b>{{ item.status_linea.nombre }}</b>
            </p>
            <p><b>Motivo:</b> {{ item.motivo }}</p>
          </div>
        </div>
        <div
          class="has-border-radius p-1 mb-8 columns"
          v-if="activeTag == 'documentos'"
        >
          <div class="column is-12">
            <p>
              <span class="font-light has-text-grey-light text-md ml-2">
                {{ item.updated_at | moment("DD-MM-YY h:mm a") }}</span
              >
            </p>
            <p>
              <preview
                :id="item.file.persona.id"
                tipo="persona"
                class="has-text-primary underline font-medium"
                >{{ item.file.persona.nombre_completo.split("|")[0] }}</preview
              >
              Se agregó el documento <b>{{ item.file.nombre }}</b>
            </p>
            <p><b>descripcion:</b> {{ item.file.descripcion }}</p>
          </div>
        </div>
        <div
          class="has-border-radius p-1 mb-8 columns"
          v-if="activeTag == 'requisitos'"
        >
          <div class="column is-12">
            <p>
              <span class="font-light has-text-grey-light text-md ml-2">
                {{ item.updated_at | moment("DD-MM-YY h:mm a") }}</span
              >
            </p>
            <p>
              Se registró un cambio de estatus en el requisito
              <span class="italic underline">{{ item.requisito.nombre }}</span>
              <span v-if="item.author">
                realizado por
                <preview
                  :id="item.author.id"
                  tipo="persona"
                  class="has-text-primary underline font-medium"
                  >{{ item.author.nombre_completo.split("|")[0] }}</preview
                >
              </span>
              a <b>{{ item.status ? item.status.nombre : "Creado" }}</b>
            </p>
            <p v-if="item.motivo"><b>Motivo:</b> {{ item.motivo }}</p>
          </div>
        </div>
        <div
          class="has-border-radius p-1 mb-8 columns"
          v-if="activeTag == 'contrato'"
        >
          <div class="column is-12">
            <p>
              <span class="font-light has-text-grey-light text-md ml-2">
                {{ item.updated_at | moment("DD-MM-YY h:mm a") }}</span
              >
            </p>
            <p>
              Se creó el contrato
              <span class="italic underline">{{ item.numero }}</span>
            </p>
            <p><b>con capital:</b> {{ item.capital | currency | hp }}</p>
          </div>
        </div>
        <div
          class="has-border-radius p-1 mb-8 columns"
          v-if="activeTag == 'disposiciones'"
        >
          <div class="column is-12">
            <p>
              <span class="font-light has-text-grey-light text-md ml-2">
                {{ item.updated_at | moment("DD-MM-YY h:mm a") }}</span
              >
            </p>
            <p>
              Se creó la disposición
              <preview
                :id="item.disposicion.id"
                tipo="credito"
                class="italic underline"
                >#{{ idToShow(item.disposicion) }}
              </preview>
              <span v-if="item.author">
                realizada por
                <preview
                  :id="item.disposicion.ejecutivo.id"
                  tipo="persona"
                  class="has-text-primary underline font-medium"
                  >{{
                    item.disposicion.ejecutivo.nombre_completo.split("|")[0]
                  }}
                </preview>
              </span>
            </p>
            <p>
              <b>con capital:</b> {{ item.disposicion.capital | currency | hp }}
            </p>
          </div>
        </div>
        <div
          class="has-border-radius p-1 mb-8 columns"
          v-if="activeTag == 'garantias'"
        >
          <div class="column is-12">
            <p>
              <span class="font-light has-text-grey-light text-md ml-2">
                {{ item.updated_at | moment("DD-MM-YY h:mm a") }}</span
              >
            </p>
            <p>
              Se creó la garantía
              <preview
                :id="item.garantia.id"
                tipo="garantia"
                class="italic underline"
                >#{{ item.garantias_id }}
              </preview>
            </p>
            <p><b>con valor:</b> {{ item.garantia.valor | currency | hp }}</p>
          </div>
        </div>
        <div
          class="has-border-radius p-1 mb-8 columns"
          v-if="activeTag == 'requisito_documentos'"
        >
          <div class="column is-12">
            <p>
              <span class="font-light has-text-grey-light text-md ml-2">
                {{ item.updated_at | moment("DD-MM-YY h:mm a") }}</span
              >
            </p>
            <p>
              Se han actualizado los documentos del requisito
              <b>{{ item.requisito.nombre }}</b>
            </p>
          </div>
        </div>
        <div
          class="has-border-radius p-1 mb-8 columns"
          v-if="activeTag == 'requisito_comentarios'"
        >
          <div class="column is-12">
            <p>
              <span class="font-light has-text-grey-light text-md ml-2">
                {{ item.updated_at | moment("DD-MM-YY h:mm a") }}</span
              >
            </p>
            <p>
              Se han actualizado los comentarios del requisito
              <b>{{ item.requisito.nombre }}</b>
            </p>
          </div>
        </div>
        <div
          class="has-border-radius p-1 mb-8 columns"
          v-if="activeTag == 'cuentas_cp'"
        >
          <div class="column is-12">
            <p>
              <span class="font-light has-text-grey-light text-md ml-2">
                {{ item.updated_at | moment("DD-MM-YY h:mm a") }}</span
              >
            </p>
            <p>
              Se ha creado una cuenta
              {{ item.cuenta_c_p.cobro ? "Por cobrar" : "Por pagar" }} del tipo
              <b>{{ item.cuenta_c_p.tipo_de_operacion.nombre }}</b>
              con número de folio
              <preview tipo="cuentaCP" :id="item.cuenta_c_p.id"
                >#{{ item.cuenta_c_p.id }}</preview
              >
            </p>
            <p>
              <b>por la cantidad de:</b>
              {{ item.cuenta_c_p.cantidad | currency | hp }}
              <span v-if="item.cuenta_c_p.iva">
                +
                <b-tooltip :label="item.cuenta_c_p.iva_id.cantidad + '%'"
                  >IVA</b-tooltip
                >
                {{ item.cuenta_c_p.iva | currency | hp }}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-12" v-if="bitacoraActiva.length == 0">
      <empty-state
        imagen="/images/files.png"
        imgclass="w-1/3"
        :titulo="tiposBitacora.find(x => x.id == activeTag).empty"
        :vertical="true"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EmptyState from "@/components/generals/EmptyState";

export default {
  name: "Bitacora",
  props: {
    arrendamiento: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      activeTag: "status_lineas",
      tiposBitacora: [
        {
          //ya
          id: "status_lineas",
          nombre: "Cambio de Estatus",
          empty: "Esta línea no ha pasado por ningún cambio de estatus",
          fields: [
            "updated_at",
            "status_linea.nombre",
            "author.nombre_completo"
          ]
        },
        {
          //ya
          id: "documentos",
          nombre: "Documentos agregados",
          empty: "Esta línea no contiene ningún documento ligado"
        },
        {
          //ya
          id: "requisitos",
          nombre: "Requisitos modificados",
          empty: "Esta línea no contiene requisitos"
        },
        {
          //ya
          id: "contrato",
          nombre: "Contrato agregado",
          empty: "Esta línea no contiene un contrato"
        },
        {
          //ya
          id: "requisito_documentos",
          nombre: "Documentos de requisitos",
          empty:
            "No se ha agregado ningún documento a algún requisito de la línea"
        },
        {
          //ya
          id: "requisito_comentarios",
          nombre: "Comentarios de requisitos",
          empty:
            "No se ha agregado ningún comentario a algún requisito de la línea"
        },
        {
          id: "cuentas_cp",
          nombre: "Cuentas cobrar / pagar agregadas",
          empty: "No se ha agregado ningúna cuenta cobrar / pagar a la línea"
        },
        {
          //ya
          id: "disposiciones",
          nombre: "Disposiciones agregadas",
          empty: "No se ha agregado ningúna disposición a la línea"
        },
        {
          //ya
          id: "garantias",
          nombre: "Garantías agregadas",
          empty: "No se ha agregado ningúna garantía a la línea"
        }
      ]
    };
  },
  methods: {
    totalPorTipo(tipo_id) {
      if (this.getBitacora == null || this.getBitacora[tipo_id] == null) {
        return 0;
      }
      return Array.isArray(this.getBitacora[tipo_id])
        ? this.getBitacora[tipo_id].length
        : Object.keys(this.getBitacora[tipo_id]).length === 0 &&
          this.getBitacora[tipo_id].constructor === Object
        ? "Sin asignar"
        : "Asignado";
    }
  },
  computed: {
    ...mapGetters("lineascredito", ["getBitacora", "getLinea"]),
    bitacoraActiva() {
      if (this.getBitacora == null) {
        return [];
      }
      return Array.isArray(this.getBitacora[this.activeTag])
        ? this.getBitacora[this.activeTag]
        : [this.getBitacora[this.activeTag]];
    },
  },
  components: {
    EmptyState
  },
  created() {
    this.$store.dispatch(
      "lineascredito/getBitacora",
      this.$route.params.idLinea
    );
  }
};
</script>
