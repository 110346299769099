<template>
  <div class="columns is-multiline" v-if="getDisposiciones != null">
    <div class="column is-9 text-left flex items-center">
      <p class="text-2xl font-bold">{{ arrendamiento ? "Arrendamientos" : "Disposiciones" }}</p>
    </div>
    <div class="column is-3 text-right">
      <b-button
        v-if="!esCreditoGrupal"
        :type="
          linea.pasiva 
            ? 'is-pasivo' 
            : arrendamiento ? 'is-arrendamiento' : 'is-primary'
        "
        icon-left="plus"
        tag="router-link"
        :to="{
          name: arrendamiento ? 'solicitar_arrendamiento' : 'solicitar_disposicion',
          params: { linea_credito_id: this.$route.params.idLinea }
        }"
      >
        {{ arrendamiento ? 'Nuevo arrendamiento' : 'Nueva disposición' }}
      </b-button>
      <b-button v-if="esCreditoGrupal && getDisposiciones.disposiciones.length == 0" type="is-primary" @click="entregarDisposiciones">Entregar disposiciones</b-button>
    </div>
    <div
      class="column is-12 pt-0 -mt-8"
      v-if="getDisposiciones.disposiciones.length > 0"
    >
      <filtros
        :disposiciones="getDisposiciones.disposiciones"
        :tipo_disposicion="linea.pasiva ? 'pasivas' : 'activas'"
        :pagina="currentPage"
        :linea-id="Number.parseInt($route.params.idLinea)"
      />
    </div>
    <div
      class="column is-12 text-left"
      v-if="getDisposiciones.disposiciones.length > 0"
    >
      <disposicion-card
        :disposicion="disposicion"
        :arrendamiento="arrendamiento"
        v-for="disposicion in getDisposicionesFiltradas
          ? getDisposicionesFiltradas.disposiciones
          : getDisposiciones.disposiciones"
        :key="disposicion.creditos_core_id"
      />
    </div>
    <div class="column is-12" v-else>
      <empty-state
        type="svg"
        imagen="/images/cuentas_cp.svg"
        :titulo="`No Hay ${arrendamiento ? 'arrendamientos' : 'disposiciones'} en esta línea`"
        :subtitulo="`Crea ${arrendamiento ? 'uno' : 'una'} en el botón de arriba a la derecha o intenta con otro filtro.`"
      />
    </div>
    <b-pagination
      class="mb-8 pl-3"
      :class="{'is-arrendamiento': arrendamiento}"
      :total="
        getDisposicionesFiltradas
          ? getDisposicionesFiltradas.total
          : getDisposiciones.total
      "
      :current.sync="currentPage"
      :range-before="2"
      :range-after="2"
      :per-page="perPage"
      icon-prev="chevron-left"
      icon-next="chevron-right"
      aria-next-label="Página siguiente"
      aria-previous-label="Página anterior"
      aria-page-label="Página"
      aria-current-label="Página actual"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EmptyState from "@/components/generals/EmptyState";
import DisposicionCard from "@/components/cards/DisposicionCard";
import Filtros from "@/components/generals/filtros/FiltrosDisposicion";
import { getLocalParam } from "@/vendors/helpers";

import LineasCreditoApi from "@/api/lineascredito.api";

export default {
  name: "LineaDetalleDisposicion",
  components: {
    EmptyState,
    DisposicionCard,
    Filtros
  },
  props: {
    linea: Object,
    arrendamiento: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentPage: 1,
      perPage: getLocalParam("paginador") || 20
    };
  },
  computed: {
    ...mapGetters("disposicion", [
      "getDisposiciones",
      "getDisposicionesFiltradas"
    ]),
    esCreditoGrupal() {
      return !!this.linea.grupo_credito_id;
    },
  },
  methods: {
    entregarDisposiciones() {
      LineasCreditoApi
        .entregarDisposicionesCreditoGrupal({ linea_id: this.$route.params.idLinea })
        .then(() => {
          this.$store.dispatch("disposicion/getDisposiciones", {
            linea_id: this.$route.params.idLinea
          });
        });
    }
  },
  mounted() {
    this.$store.dispatch("disposicion/getDisposiciones", {
      linea_id: this.$route.params.idLinea
    });
  }
};
</script>
