<template>
  <Layout class="main-cols" v-if="getLinea != null">
    <div
      class="container is-fluid lg:ml-0"
      v-if="$route.name === 'detalle_linea'"
    >
      <div class="columns is-multiline flex-col md:flex-row">
        <div class="column is-9">
          <p class="title has-text-grey-darker text-left mb-0">
            Línea de crédito {{ getLinea.pasiva ? "pasiva" : "activa" }} #{{
              getLinea.id
            }}
            <span class="font-light has-text-grey-light">
              con estatus en
              <span
                :class="
                  getColor(
                    getLinea.status_linea_directo
                      ? getLinea.status_linea_directo.nombre
                      : 'NA'
                  )
                "
                class="font-medium"
                >{{
                  getLinea.status_linea_directo
                    ? getLinea.status_linea_directo.nombre
                    : "N/A"
                }}</span
              >
            </span>
          </p>
          <p class="has-text-warning text-left mt-1 mb-0" v-if="getLinea.lineas_reestructuradas && getLinea.lineas_reestructuradas.length > 0">
            {{ getLinea.lineas_reestructuradas.length > 1 
                ? 'Esta línea fue reestructurada a partir de las líneas' 
								: 'Esta línea fue reestructurada a partir de la línea' }}
            
            <router-link
              v-for="(reestructura, index) in getLinea.lineas_reestructuradas"
              :key="index"
              :to="'/linea/' + reestructura.id"
              :class="{
                'has-text-primary-darker': getLinea.pasiva == 0,
                'has-text-pasivo': getLinea.pasiva == 1
              }"
            >
              {{ reestructura.id }} {{ index != getLinea.lineas_reestructuradas.length - 1 ? ',' : '' }}
            </router-link>
          </p>
          <p class="has-text-warning text-left mt-1 mb-0" v-if="getLinea.lineas_renovadas && getLinea.lineas_renovadas.length > 0">
            {{ getLinea.lineas_renovadas.length > 1 
                ? 'Esta línea fue renovada a partir de las líneas' 
								: 'Esta línea fue renovada a partir de la línea' }}
            
            <router-link
              v-for="(renovada, index) in getLinea.lineas_renovadas"
              :key="index"
              :to="'/linea/' + renovada.id"
              :class="{
                'has-text-primary-darker': getLinea.pasiva == 0,
                'has-text-pasivo': getLinea.pasiva == 1
              }"
            >
              {{ renovada.id }} {{ index != getLinea.lineas_renovadas.length - 1 ? ',' : '' }}
            </router-link>
          </p>
        </div>
        <div class="column is-3 text-right">
          <b-dropdown
            aria-role="list"
            position="is-bottom-left"
            class="text-left"
          >
            <b-button
              class="w-full is-light has-text-grey-darker"
              slot="trigger"
              slot-scope="{ active }"
              :outlined="true"
              :icon-right="active ? 'caret-up' : 'caret-down'"
            >
              Opciones
            </b-button>
            <b-dropdown-item aria-role="listitem" has-link>
              <router-link
                :to="{
                  name: 'editar_linea',
                  params: {
                    idLinea: $route.params.idLinea
                  }
                }"
              >
                Editar línea
              </router-link>
            </b-dropdown-item>
            <b-dropdown-item
              aria-role="listitem"
              @click="() => (is_modal_edo_cuenta = true)"
            >
              Imprimir Estado de cuenta
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="column is-12">
          <tabs
            :tabs="modifyTabsArrendamiento()"
            v-model="activeTab"
            @change="changeTab"
            :pasivo="getLinea.pasiva == 1 ? true : false"
            :arrendamiento="esArrendamiento"
          />
        </div>
        <div
          class="column animated is-12-mobile sm:order-3 md:order-none is-12"
          v-show="activeTab == 0"
        >
          <informacion :linea="getLinea" />
        </div>
        <div class="column" v-if="activeTab == 1">
          <bitacora :arrendamiento="esArrendamiento" />
        </div>
        <div class="column" v-if="activeTab == 2">
          <documentacion
            :pasiva="getLinea.pasiva == 1 ? true : false"
            :arrendamiento="esArrendamiento"
            tipo="linea"
          />
        </div>
        <div class="column" v-if="activeTab == 3">
          <contrato :linea="getLinea" />
        </div>
        <div class="column" v-if="activeTab == 4">
          <cuentas-c-p :linea="getLinea" :arrendamiento="esArrendamiento" />
        </div>
        <div class="column" v-if="activeTab == 5">
          <cuentas-financiadas
            :origen="getLinea.id"
            :origenIdToShow="getLinea.id"
            :financiadas="getLinea.creditos_financiados"
            tipo-de-origen="Linea"
          />
        </div>
        <div class="column" v-if="activeTab == 6">
          <disposiciones :linea="getLinea" :arrendamiento="esArrendamiento" />
        </div>
        <div class="column is-12" v-if="activeTab == 7 && !esArrendamiento">
          <garantias :linea="getLinea" />
        </div>
        <div class="column" v-if="(activeTab == 8 && !esArrendamiento) || (activeTab == 7 && esArrendamiento)">
          <operaciones-diarias
            :operaciones="getMovimientos"
            v-if="getMovimientos"
            @page-change="loadMovimientos"
          />
        </div>
      </div>
    </div>
    <router-view v-else />
    <portal to="modalContainer">
      <b-modal
        v-if="is_modal_edo_cuenta"
        :active.sync="is_modal_edo_cuenta"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
      >
        <modal-estado-cuenta
          class="modal-has-overflow"
          :linea-id="getLinea.id"
          :fecha-minima="getLinea.fecha_autorizacion || getLinea.created_at"
        />
      </b-modal>
    </portal>
  </Layout>
</template>

<script>
import { mapGetters } from "vuex";
import Bitacora from "@/components/Lineas/detalle/Bitacora";
import Documentacion from "@/components/Disposicion/Detalle/Documentacion";
import Informacion from "@/components/Lineas/detalle/Informacion";
import Contrato from "@/components/Lineas/detalle/Contrato";
import CuentasCP from "@/components/Lineas/detalle/CuentasCP";
import Disposiciones from "@/components/Lineas/detalle/Disposiciones";
import Garantias from "@/components/Lineas/detalle/Garantias";
import Tabs from "@/components/generals/Tabs";
import OperacionesDiarias from "@/components/generals/OperacionesDiarias";
import ModalEstadoCuenta from "@/components/modals/ModalEstadoCuenta";
import CuentasFinanciadas from "@/views/cuentas/CuentasFinanciadas.vue";

export default {
  name: "detalleLinea",
  components: {
    Bitacora,
    Documentacion,
    Tabs,
    Informacion,
    Contrato,
    CuentasCP,
    Disposiciones,
    Garantias,
    OperacionesDiarias,
    CuentasFinanciadas,
    ModalEstadoCuenta,
  },
  data() {
    return {
      activeTab: 0,
      is_modal_edo_cuenta: false
    };
  },
  watch: {
    $route: {
      handler: async function() {
        if (this.getLinea && this.getLinea.id != this.$route.params.idLinea) {
          await this.$store.dispatch("lineascredito/resetState");
          await this.$store.dispatch(
            "lineascredito/getLineaById",
            this.$route.params.idLinea
          );
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    changeTab(val) {
      this.activeTab = val;
      if ((val == 8 || val == 7 && this.esArrendamiento) && this.getMovimientos == null) {
        this.loadMovimientos(1);
      }
    },
    getColor(nombre_status) {
      switch (nombre_status) {
        case "Prospecto":
          return this.getLinea.pasiva ? "has-text-pasivo" : this.esArrendamiento ? "has-text-arrendamiento" : "has-text-primary";
        default:
          return "has-text-grey-darker";
      }
    },
    loadMovimientos(pagina) {
      this.$store.dispatch("lineascredito/getMovimientos", {
        linea_id: this.$route.params.idLinea,
        pagina: pagina
      });
    },
    modifyTabsArrendamiento() {
      return this.esArrendamiento ? this.tabs.filter(tab => tab.label != 'Garantias') : this.tabs;
    }
  },
  computed: {
    ...mapGetters("lineascredito", ["getLinea", "getMovimientos"]),
    esArrendamiento() {
      return this.getLinea && this.getLinea.producto_financiero.tipo_credito ? this.getLinea.producto_financiero.tipo_credito.tipo === "Arrendamiento Puro" : false;
    },
    routeName() {
      return this.$route.name;
    },
    tabs() {
      return [
        {
          label: "Información general"
        },
        {
          label: "Bitácora"
        },
        {
          label: "Documentación"
        },
        {
          label: "Contrato"
        },
        {
          label: "Cuentas por " + (this.getLinea.pasiva ? "pagar" : "cobrar")
        },
        {
          label: "Cuentas financiadas"
        },
        {
          label: this.esArrendamiento ? "Arrendamientos" : "Disposiciones"
        },
        {
          label: "Garantias"
        },
        {
          label: "Movimientos financieros"
        }
      ];
    }
  },
  async created() {
    if (!this.getLinea) {
      await this.$store.dispatch(
        "lineascredito/getLineaById",
        this.$route.params.idLinea
      );
    }

    if (this.esArrendamiento && this.tabs) {
      this.tabs.filter(tab => tab.label != "Garantias");
    } 
  },
};
</script>